<template>
    <div class="edu-frame">
        <edu-nav-bar title="填写电子发票" :show="show" @showPopup="show=true" @hidePopup="show=false"></edu-nav-bar>
        <div class="content-frame">
            <div class="edu-group" style="margin: 20px auto;">
                <div class="step-tips">请输入开电子发票信息</div>
                <div class="step-tips-detail">请确保以下信息正确，成功缴费后，系统将自动开电子发票！</div>
                <hr class="split-line"/>
                <div class="class-form">
                    <van-field label-width="80px" readonly v-model="formData.mobileTelNo" required label="手机号码" placeholder="请输入手机号码" clearable/>
                    <van-field label-width="80px" required name="radio" label="发票类型">
                        <template #input>
                            <van-radio-group v-model="formData.lsrtype" direction="horizontal">
                                <van-radio name="1">个人</van-radio>
                                <van-radio name="2">单位</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                    <van-field label-width="90px" v-model="formData.taxDepName" required label="开票单位名称" placeholder="请输入开票单位名称" clearable/>
                    <van-field label-width="80px" v-if="formData.lsrtype == 2" v-model="formData.depTaxNo" required label="单位纳税人识别号" placeholder="请输入单位纳税人识别号" clearable/>
                </div>

              <div style="display: flex;justify-content: center;align-items: center;" v-if="customerTaxs.length > 0">
                <van-button :loading="loading" square type="primary" @click="showCustomerTax" size="small" style="margin: 0 10px;background-color: orange;border-color: orange;">选择其他开票信息</van-button>
              </div>

            </div>
        </div>

        <div class="bottom-frame">
            <!--<van-button square block type="info" @click="$router.go(-1)">上一步</van-button>-->
            <van-button :loading="loading" square block type="primary" @click="toBdsd">下一步</van-button>
        </div>

      <van-popup v-model="showCustomerTaxDlg" :close-on-click-overlay="false">
        <div style="width: 90vw;">
          <div style="text-align: center;padding: 5px 0px;background-color: #39a9ed;color: white;">请选择其他开票信息</div>
          <div style="display: flex;font-size: 14px;padding: 5px 0px;background-color: #eeeeee;">
            <div style="flex: 1;text-align: center;">开票单位名称</div>
            <div style="flex: 1;text-align: center;">单位纳税人识别号</div>
            <div style="width: 110px;text-align: center;">操作</div>
          </div>
          <div style="height: 70vh;font-size: 14px;overflow: auto;">
            <div v-for="(ct,index) in customerTaxs" :key="index"  style="display: flex;font-size: 14px;align-items: center;padding: 10px 0px;border-bottom: 1px solid #eeeeee;">
              <div style="flex: 1;text-align: center;">{{ ct.taxDepName }}</div>
              <div style="flex: 1;text-align: center;">{{ ct.taxDepNo }}</div>
              <div style="width: 110px;text-align: center;">
                <van-button square type="primary" size="small" @click="selectTax(ct)">选择</van-button>
                <van-button square type="danger" size="small" @click="deleteTax(ct)" style="margin-left: 3px;">删除</van-button>
              </div>
            </div>
          </div>
          <van-button :loading="loading" square block type="primary" style="background-color: orange;border-color: orange;" @click="showCustomerTaxDlg=false">返回</van-button>
        </div>
      </van-popup>
    </div>
</template>
<script>
    import {Field, Button, RadioGroup, Radio, Popup } from 'vant';
    import EduNavBar from "@/components/EduNavBar";
    import Tools from "@/api/Tools";
    import BaomingApi from "@/api/BaomingApi";
    import {Dialog} from "vant";
    export default {
        components: {
            EduNavBar,
            VanField: Field,
            VanButton: Button,
            VanRadioGroup: RadioGroup,
            VanRadio: Radio,
            VanPopup: Popup
        },
        data() {
            return {
                show: false,
                formData: { },
                loading: true,
                customerTaxs: [],
                showCustomerTaxDlg: false
            }
        },
        name: 'login',
        methods: {
            toBdsd() {
                if (this.loading) {
                  return;
                }
                this.formData.custId = Tools.getCurCust().custId
                BaomingApi.updateTaxDep(this.formData).then(response=>{
                    if (response.code == 100) {
                        // 选择报读时段
                        this.$router.push({ name: 'bdsd' })
                    } else {
                        this.$dialog.alert({message: response.msg})
                    }
                })
            },
            showCustomerTax() {
              this.showCustomerTaxDlg = true
            },
            selectTax(ct) {
              console.log(ct)
              this.formData.depTaxNo = ct.taxDepNo
              this.formData.taxDepName = ct.taxDepName
              this.showCustomerTaxDlg = false
            },
            deleteTax(ct) {
              var taxId = ct.taxId
              Dialog.confirm({
                title: '提示',
                message: '是否确定删除该发票信息?',
              }).then(() => {
                BaomingApi.deleteTaxDep(taxId).then(response => {
                  if (response.code == 100) {
                    // 选择报读时段
                    this.$toast.success('删除成功')
                    this.getTaxdep()
                  } else {
                    this.$toast.fail(response.msg)
                  }
                })
              }).catch(() => {
              });
            },
            getTaxdep() {
              this.loading = true
              this.cust = Tools.getCurCust()
              BaomingApi.getTaxdep(this.cust).then(response=>{
                this.loading = false
                var taxDep = response.res.taxDep
                var customerTaxs = response.res.customerTaxs

                if (taxDep.depTaxNo != null && taxDep.depTaxNo != '') {
                  taxDep.lsrtype = '2'
                } else {
                  taxDep.lsrtype = '1'
                }
                this.formData = taxDep
                this.customerTaxs = customerTaxs
              }).catch(()=>{this.loading = false})
            }
        },
        mounted() {
          this.getTaxdep()
        }
    }
</script>
<style scoped>
    .form-tips{
        margin-top: 5px;
        margin-bottom: 5px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
    }

    .split-line {
        width: 90%;
        border: 0;
        height: 1px;
        background-color: #dddddd;
    }

</style>
